import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import CircularProgress from "@mui/material/CircularProgress";
import { PrimaryText } from "@components";
import { CheckLanguage } from "@utils";
import { getTermCondition } from "@store";

function TermAndCondition() {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const termConditionData = useSelector(
    (state) => state.footers.termConditionData
  );
  const [state, setState] = useState({
    loading: true,
  });

  useEffect(() => {
    let dateLanguage = CheckLanguage() || "en";
    dispatch(getTermCondition(`terms-cond?locale=${dateLanguage}`)).then(
      (res) => {
        if (res?.payload?.status === 200 && res?.payload?.data) {
          setState((prev) => ({ ...prev, loading: false }));
        }
      }
    );
  }, [dispatch]);

  return (
    <Root>
      <div className="w-full h-full whitespace-pre-line flex flex-col">
        <PrimaryText className={title}>{t("footer.termCondition")}</PrimaryText>
        <Component>
          {state.loading ? (
            <div className="w-full flex justify-center py-20">
              <CircularProgress
                sx={{ color: "#ffffff" }}
                size={50}
                thickness={5}
              />
            </div>
          ) : (
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={termConditionData.toString()}
            />
          )}
        </Component>
      </div>
    </Root>
  );
}

const Root = tw.div`
px-4
py-8
w-full
h-auto
min-h-[650px]
`;

const title = `
text-3xl 
text-textColor 
font-bold 
font-[Cambay] 
text-center 
self-center 
mb-8 
underline underline-offset-8
`;

const Component = tw.div`
break-words 
hyphens-auto 
text-textColor 
opacity-80
`;

export default TermAndCondition;
