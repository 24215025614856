const SERVER_HOST = process.env.REACT_APP_API_URL;
const BASE_API_URL = process.env.REACT_APP_BASE_URL;
const BASE_API_USERNAME = process.env.REACT_APP_API_USERNAME;
const BASE_API_PASSWORD = process.env.REACT_APP_API_PASSWORD;
const WEATHER_APPID = process.env.REACT_APP_WEATHER_APPID;
const STRIPE_PUBLISH_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

const GET_TEE_TIME = `${BASE_API_URL}/teetimes`;
const GET_BOOKING = `${BASE_API_URL}/bookings`;
const GET_FACILITIES_ON_SANDBOX = `${BASE_API_URL}/facilities`;

const GET_TEES = `${SERVER_HOST}/api/tees`;
const GET_TEES_PAGINATE = `${SERVER_HOST}/api/tees-paginate`;
const GET_HEADER = `${SERVER_HOST}/api/header`;
const GET_FACILITIES = `${SERVER_HOST}/api/facilities`;
const GET_CLUBS = `${SERVER_HOST}/api/club`;
const GET_COURSES = `${SERVER_HOST}/api/courses`;
const GET_FOOTER = `${SERVER_HOST}/api/footer`;
const GET_MENU = `${SERVER_HOST}/api/tree-menus/menu`;
const TEE_TIME_BOOKING = `${SERVER_HOST}/api/booking/tee-booking`;
const TEE_TIME_BOOKING_CANCEL = `${SERVER_HOST}/api/booking/booking/cancel`;
const GET_BOOKINGS = `${SERVER_HOST}/api/booking/bookings`;
const LOGIN = `${SERVER_HOST}/api/auth/local`;
const REGISTER = `${SERVER_HOST}/api/auth/local/register`;
const CHANGE_PASSWORD = `${SERVER_HOST}/api/auth/change-password`;
const FORGOT_PASSWORD = `${SERVER_HOST}/api/auth/forgot-password`;
const RESET_PASSWORD = `${SERVER_HOST}/api/auth/reset-password`;
const GET_USER = `${SERVER_HOST}/api/users/me`;
const USER_UPDATE = `${SERVER_HOST}/api/users`;
const UPLOAD_IMAGE = `${SERVER_HOST}/api/upload`;
const SETTING = `${SERVER_HOST}/api/setting`;
const FOOTER_PAGE = `${SERVER_HOST}/api/page/static-page`;
const CREATE_CUSTOMER = `${SERVER_HOST}/api/payment/create-customer`;
const CREATE_PAYMENT = `${SERVER_HOST}/api/payment/create-payment`;
const CREATE_STRIPE_CUSTOMER = `${SERVER_HOST}/api/payment/create-stripe-customer`;
const CREATE_PAYMENT_INTENT = `${SERVER_HOST}/api/payment/create-payment-intent`;
const PAYMENT_UPDATE_BOOKING = `${SERVER_HOST}/api/payment/update-booking`;
const CREATE_TOURNAMENT_PAYMENT_INTENT = `${SERVER_HOST}/api/tournament-payment/create-tournament-payment-intent`;
const UPDATE_PAYMENT_STATUS = `${SERVER_HOST}/api/payment/update-status`;
const USER_CARD = `${SERVER_HOST}/api/card/user-cards`;
const GET_COMPETITION = `${SERVER_HOST}/api/competitions`;
const GET_TOURNAMENT = `${SERVER_HOST}/api/tournaments`;
const GET_TOURNAMENT_TEE_TIME = `${SERVER_HOST}/api/tournament-tee-times`;
const GET_COMPETITION_TEE_TIME = `${SERVER_HOST}/api/open-competition/tee-times`;
const BOOK_TOURNAMENT = `${SERVER_HOST}/api/tournament-booking/tee-booking`;
const GET_BOOK_TOURNAMENT = `${SERVER_HOST}/api/tournament-bookings`;
const GET_COUNTY = `${SERVER_HOST}/api/countries`;
const GET_PAYMENT_DETAIL = `${SERVER_HOST}/api/payment/detail`;

const Config = {
  SERVER_HOST,
  BASE_API_URL,
  BASE_API_USERNAME,
  BASE_API_PASSWORD,
  WEATHER_APPID,
  STRIPE_PUBLISH_KEY,

  GET_TEE_TIME,
  GET_BOOKING,
  GET_FACILITIES_ON_SANDBOX,

  GET_TEES,
  GET_TEES_PAGINATE,
  GET_HEADER,
  GET_FACILITIES,
  GET_CLUBS,
  GET_COURSES,
  GET_FOOTER,
  GET_MENU,
  TEE_TIME_BOOKING,
  TEE_TIME_BOOKING_CANCEL,
  GET_BOOKINGS,
  LOGIN,
  REGISTER,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_USER,
  USER_UPDATE,
  UPLOAD_IMAGE,
  SETTING,
  FOOTER_PAGE,
  CREATE_CUSTOMER,
  CREATE_PAYMENT,
  CREATE_STRIPE_CUSTOMER,
  CREATE_PAYMENT_INTENT,
  PAYMENT_UPDATE_BOOKING,
  CREATE_TOURNAMENT_PAYMENT_INTENT,
  UPDATE_PAYMENT_STATUS,
  USER_CARD,
  GET_COMPETITION,
  GET_TOURNAMENT,
  GET_TOURNAMENT_TEE_TIME,
  GET_COMPETITION_TEE_TIME,
  BOOK_TOURNAMENT,
  GET_BOOK_TOURNAMENT,
  GET_COUNTY,
  GET_PAYMENT_DETAIL,
};

export default Config;
