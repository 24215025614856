import { get } from "@utils";

const CheckLanguage = () => {
  let dateLanguage = "en";
  const selectLanguage = get("Language");
  if (selectLanguage !== null) {
    dateLanguage =
      selectLanguage === "ge"
        ? "de-DE"
        : selectLanguage === "fr"
        ? "fr-FR"
        : selectLanguage;
  } else {
    dateLanguage = "en";
  }

  return dateLanguage;
};

export default CheckLanguage;
