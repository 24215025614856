import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import tw from "tailwind-styled-components";
import { PrimaryText } from "@components";
import xIcon from "@assets/image/xIcon.png";
import fbIcon from "@assets/image/facebookIcon.png";
import instaIcon from "@assets/image/instaIcon.png";
import "../containerStyle.css";

function Footer() {
  const navigate = useNavigate();
  const footerMenu = useSelector((state) => state.menu.menus);

  localStorage.removeItem("li_adsId");

  const goPage = (e) => {
    navigate(e?.contant?.url);
  };

  const FooterTxt = (props) => {
    return (
      <button onClick={() => goPage(props)}>
        <PrimaryText className={`${footerText} footer-underline-animation`}>
          {props.children}
        </PrimaryText>
      </button>
    );
  };

  const SocialIcon = () => {
    return (
      <SocialView>
        <SocialLogo target="_blank" href="https://www.facebook.com/thekclub/">
          <img
            src={fbIcon}
            alt="fb"
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </SocialLogo>
        <SocialLogo
          target="_blank"
          href="https://www.instagram.com/thekclubireland/"
        >
          <img
            src={instaIcon}
            alt="ins"
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </SocialLogo>
        <SocialLogo target="_blank" href="https://twitter.com/thekclub">
          <img
            src={xIcon}
            alt="ins"
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </SocialLogo>
      </SocialView>
    );
  };

  return (
    <>
      <Line></Line>
      {footerMenu?.length > 0 && (
        <Container>
          <div>
            {footerMenu?.map((item, index) => (
              <React.Fragment key={`footer${index}`}>
                {item?.slug !== "header" && (
                  <FooterView>
                    {item?.items?.map((items, index2) => (
                      <React.Fragment key={`footer${index2}`}>
                        <FooterTxt contant={items}>{items?.title}</FooterTxt>
                      </React.Fragment>
                    ))}
                  </FooterView>
                )}
              </React.Fragment>
            ))}

            <SocialIcon />
          </div>
        </Container>
      )}
    </>
  );
}

const Container = tw.div`
w-full 
h-auto 
flex 
flex-col 
justify-center
items-center
`;
const Line = tw.div`
w-full 
h-[1.2px] 
bg-primary
mt-[10px]
`;
const FooterView = tw.div`
w-auto 
h-auto 
flex 
flex-wrap 
items-center 
xl:justify-center justify-start 
xl:px-[5px] px-6
py-6 
gap-x-8 
gap-y-4
`;
const footerText = `
text-textColor
text-[1.075rem] 
font-bold 
font-[Cambay] 
`;
const SocialView = tw.div`
w-full 
h-auto 
flex 
flex-wrap 
items-center 
justify-center
px-6
pb-12
gap-x-8 
gap-y-4
`;
const SocialLogo = tw.a`
duration-500 
hover:transform 
hover:scale-125
`;

export default Footer;
