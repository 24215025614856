import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import tw from "tailwind-styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { set } from "@utils";
import { PrimaryText } from "@components";
import {
  getCompetitionTeeTime,
  remove_CompetitionTeeTime,
  tournamentLoader,
} from "@store";
import playerImg from "@assets/image/user.png";
import "../../containerStyle.css";

const BookNowBtn = (e) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { competitionId } = useParams();
  const singleTournaments = useSelector(
    (state) => state.competition?.singleTournaments
  );
  const bookingSlot = [
    { id: 1, name: `${t("tournament.book")} 1 ${t("tournament.slot")}` },
    { id: 2, name: `${t("tournament.book")} 2 ${t("tournament.slot")}` },
    { id: 3, name: `${t("tournament.book")} 3 ${t("tournament.slot")}` },
    { id: 4, name: `${t("tournament.book")} 4 ${t("tournament.slot")}` },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const bookNow = (r) => {
    let competitonBookData = {
      slot: r,
      teeTime: e,
      data: singleTournaments?.tournament,
      tournamentId: singleTournaments?.tournament?.id,
    };
    set("CompetitonBookData", JSON.stringify({ competitonBookData }));
    navigate(`/tournament/${competitionId}/players`);
    localStorage.removeItem("AddToCartsData");
    window.scrollTo(0, 0);
  };

  return (
    <Listbox onChange={bookNow}>
      <div className="relative mt-2 sm:pl-0 pl-[10px]">
        <ListboxButton className="relative w-full sm:min-w-[130px] min-w-[100px] flex items-center justify-center cursor-pointer rounded-full bg-primary hover:bg-primary2 pt-[6px] pb-[1px] text-gray-900 font-semibold ">
          <span className="flex items-center font-bold font-[Cambay]">
            {t("tournament.bookNow")}
          </span>
        </ListboxButton>
        <ListboxOptions
          anchor="bottom"
          className={
            " mt-1 max-h-60 rounded-xl bg-[#f3f3f3] py-2 px-6 pt-3 ml-[-20px] border border-[#969696] "
          }
        >
          {bookingSlot.slice(0, e?.data?.players).map((person) => (
            <ListboxOption
              key={person.id}
              value={person}
              className={({ active }) =>
                classNames(
                  "text-textColor2 relative cursor-pointer pt-[6px] pb-[1px] mb-3 bg-primary hover:bg-primary2 w-full flex items-center justify-center rounded-md shadow-[0_4px_4px_0px_#00000040] font-[Cambay] px-4"
                )
              }
            >
              <div className="flex items-center">
                <span
                  className={classNames(
                    "ml-3 block truncate font-semibold text-[16px] font-[Cambay]"
                  )}
                >
                  {person.name}
                </span>
              </div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
};

const BookCompetition = () => {
  const { t } = useTranslation("common");
  const { competitionId } = useParams();
  const dispatch = useDispatch();
  const clubData = useSelector((state) => state.clubs.clubsData);
  const facilitiesData = clubData?.data?.facility;
  const singleTournaments = useSelector(
    (state) => state.competition?.singleTournaments
  );
  const apiTimer = useSelector((state) => state.setting.time);
  const playerTypeId = apiTimer?.data?.data;
  const competitionsTeeTime = useSelector(
    (state) => state.competition?.competitionsTeeTime
  );
  const isTournamentLoader = useSelector(
    (state) => state.competition?.tournamentLoader
  );
  const isCompetitionLoader = useSelector(
    (state) => state.competition?.competitionLoader
  );
  const allTournaments = useSelector(
    (state) => state.competition?.allTournaments
  );

  const [state, setState] = useState({
    players: [1, 2, 3, 4],
    filterdTournament: [],
    filterTorunamentTeeTime: [],
  });

  // useEffect(() => {
  //   if (allTournaments?.length) {
  //     let currentTimeFilter = allTournaments?.filter(function (date) {
  //       if (date) {
  //         const dt = new Date(Date.now() - 3600 * 1000 * 24);
  //         const df = new Date(date?.tournamentDate);
  //         if (df.getTime() > dt.getTime()) return df;
  //       }
  //     });

  //     let filterTournament = currentTimeFilter?.filter((res) => {
  //       return res?.competitionId?.id === +competitionId;
  //     });
  //     // console.log('*** allTournaments ***', allTournaments);
  //     // console.log('*** filterTournament ***', filterTournament);
  //     setState((prev) => ({
  //       ...prev,
  //       filterdTournament: filterTournament,
  //     }));
  //   }
  // }, [allTournaments]);

  useEffect(() => {
    if (allTournaments?.length) {
      let currentTimeFilter = allTournaments.filter((date) => {
        if (date) {
          const dt = new Date(Date.now() - 3600 * 1000 * 24);
          const df = new Date(date?.tournamentDate);
          return df.getTime() > dt.getTime();
        }
        return false;
      });

      let filterTournament = currentTimeFilter.filter((res) => {
        return res?.competitionId?.id === +competitionId;
      });

      setState((prev) => ({
        ...prev,
        filterdTournament: filterTournament,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTournaments]);

  useEffect(() => {
    let apiData = {
      holes: singleTournaments?.tournament?.holesToPlay
        ? singleTournaments?.tournament?.holesToPlay
        : 18,
      players: 1,
      bookingDate: singleTournaments?.tournament?.tournamentDate,
      facilitiesId: facilitiesData?.id,
      playerTypeId: playerTypeId?.guestPlayerTypeId,
      type: "tournament",
    };

    if (
      competitionsTeeTime?.teeTimes?.length &&
      singleTournaments &&
      singleTournaments?.tournament?.tournamentDate !== null &&
      moment(competitionsTeeTime?.teeTimes[0]?.time).isSame(
        singleTournaments?.tournament?.tournamentDate,
        "day"
      )
    ) {
    } else {
      if (
        playerTypeId &&
        facilitiesData?.id &&
        singleTournaments &&
        singleTournaments?.tournament?.tournamentDate !== null &&
        singleTournaments?.tournament?.holesToPlay
      ) {
        dispatch(tournamentLoader(true));
        dispatch(getCompetitionTeeTime(apiData)).then(async () => {
          await dispatch(tournamentLoader(false));
        });
      } else {
        dispatch(tournamentLoader(false));
        dispatch(remove_CompetitionTeeTime(null));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    playerTypeId,
    facilitiesData,
    singleTournaments,
    singleTournaments?.tournament?.tournamentDate,
  ]);

  useEffect(() => {
    let filtersTeeTime;
    if (
      singleTournaments?.tournament?.zestTournamentId &&
      competitionsTeeTime?.teeTimes?.length
    ) {
      filtersTeeTime = competitionsTeeTime?.teeTimes?.filter((res) => {
        return res?.eventId === singleTournaments?.tournament?.zestTournamentId;
      });
      setState((prev) => ({
        ...prev,
        filterTorunamentTeeTime: filtersTeeTime,
      }));
    } else if (
      competitionsTeeTime?.teeTimes?.length &&
      !singleTournaments?.tournament?.zestTournamentId
    ) {
      setState((prev) => ({
        ...prev,
        filterTorunamentTeeTime: competitionsTeeTime?.teeTimes,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        filterTorunamentTeeTime: [],
      }));
    }
  }, [singleTournaments, competitionsTeeTime]);

  const BookedPlayers = (props) => {
    return (
      <>
        <div className="flex flex-col items-center justify-center gap-y-[3px] md2:min-w-[83px] sm:min-w-0 min-w-[60px]">
          <PrimaryText className={`text-[#969696]`}>
            {t("tournament.player") + props?.player}
          </PrimaryText>
          <PrimaryText className={`text-[#969696]`}>
            {t("tournament.booke")}
          </PrimaryText>
        </div>
      </>
    );
  };

  const NotBookedPlayers = (props) => {
    return (
      <div className="flex flex-row items-center justify-center gap-x-[3px] md2:min-w-[83px] sm:min-w-0 min-w-[60px]">
        <img
          src={playerImg}
          alt="img"
          className="w-[25px] h-[25px] rounded-full md2:flex hidden"
        />
        <PrimaryText className={`text-[#969696]`}>
          {t("tournament.player") + props?.player}
        </PrimaryText>
      </div>
    );
  };

  const ShowMessage = (props) => {
    return (
      <div className="w-full h-full flex justify-center items-center my-[20px]">
        <PrimaryText
          className={"center text-xl text-white255 animate-pulse text-center "}
        >
          {props.title}
        </PrimaryText>
      </div>
    );
  };

  const BookingView = () => {
    return (
      <>
        {singleTournaments?.tournament?.title ? (
          <div className={bookingsView}>
            {state.filterTorunamentTeeTime?.length > 0 &&
              state.filterTorunamentTeeTime?.map((data, index3) => (
                <React.Fragment key={`teeTime${index3}`}>
                  <Component
                    className={`${
                      index3 % 2 !== 0 ? "bg-white" : "bg-[#F1F1F1]"
                    } ${
                      0 === index3
                        ? " rounded-t-xl"
                        : index3 === state.filterTorunamentTeeTime?.length - 1
                        ? "rounded-b-xl"
                        : ""
                    }`}
                  >
                    <PrimaryText className="text-[#969696] font-bold text-[17px] sm:px-0 px-[10px]">
                      {moment(data?.time).format("HH:mm")}
                    </PrimaryText>
                    <div className="sm:w-full w-[380px] h-auto flex flex-row items-center sm:justify-around justify-start gap-x-2 overflow-x-scroll overflow-y-hidden custom-scroll">
                      {state.players?.map((e2, index2) => (
                        <React.Fragment key={`notBooked${index2}`}>
                          {data?.players < index2 + 1 ? (
                            <BookedPlayers player={e2} data={data} />
                          ) : (
                            <NotBookedPlayers player={e2} data={data} />
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <BookNowBtn data={data} slot={0} />
                  </Component>
                </React.Fragment>
              ))}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <Root>
      <div
        className={`${scrollDivs} ${
          state.filterTorunamentTeeTime?.length > 0
            ? "bg-white"
            : "bg-transparent"
        }`}
      >
        {BookingView()}

        {singleTournaments?.tournament ? (
          <></>
        ) : !isCompetitionLoader && state.filterdTournament?.length === 0 ? (
          <ShowMessage title={t("competition.noCompetition")} />
        ) : (
          <div className={competition_Loader}>
            <CircularProgress
              sx={{ color: "#9c8053" }}
              size={50}
              thickness={5}
            />
          </div>
        )}

        {singleTournaments?.tournament &&
          !isCompetitionLoader &&
          (state.filterTorunamentTeeTime?.length > 0 ? (
            <></>
          ) : !isTournamentLoader &&
            state.filterTorunamentTeeTime?.length === 0 ? (
            <ShowMessage title={t("competition.noTeeTime")} />
          ) : (
            <div className={tournament_Loader}>
              <CircularProgress
                sx={{ color: "#9c8053" }}
                size={50}
                thickness={5}
              />
              <ShowMessage title={t("competition.isLoading")} />
            </div>
          ))}
      </div>
    </Root>
  );
};

const Root = tw.div`
w-full
h-auto
flex
flex-col
gap-y-4
items-center
justify-start
mb-6
`;
const scrollDivs = `
w-full
h-auto
rounded-xl
`;
const bookingsView = `
w-full
h-auto
flex
flex-col
rounded-xl
`;
const Component = tw.div`
w-full
h-auto
flex
flex-row
items-center 
justify-between
py-2
md:px-4 px-[10px]
`;

const competition_Loader = `
flex 
w-full h-auto 
justify-center items-center 
self-center justify-self-center 
pt-20
`;

const tournament_Loader = `
flex flex-col 
w-full h-auto 
justify-center items-center 
self-center justify-self-center 
pt-20
`;

export default BookCompetition;
