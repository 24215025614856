import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import tw from "tailwind-styled-components";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Zoom } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { PrimaryText } from "@components";
import { getTermCondition, getPrivacyPolicy } from "@store";
import { CheckLanguage } from "@utils";

function CustomModal(props) {
  return (
    <Dialog
      open={props.openModal}
      onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <PrimaryText className="text-gray118 text-md  font-[Cambay]">
          {props.description}
        </PrimaryText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeModal} sx={{ color: "#000000" }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TimeOutModal(props) {
  return (
    <Dialog
      open={props.openModal}
      // onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      <div className=" bg-[#eae9e9]">
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <PrimaryText className="text-gray118 text-lg  font-[Cambay]">
            Your booking session is expired do you want to continue your
            booking?
          </PrimaryText>
        </DialogContent>
        <BtnView>
          <DialogActions>
            <Button
              onClick={props.reStartModal}
              className=" shadow-2xl drop-shadow-2xl"
              sx={{ color: "#000000", backgroundColor: "white" }}
            >
              YES
            </Button>
          </DialogActions>
          <DialogActions>
            <Button
              onClick={props.closeModal}
              className=" shadow-2xl drop-shadow-2xl"
              sx={{ color: "#000000", backgroundColor: "white" }}
            >
              NO
            </Button>
          </DialogActions>
        </BtnView>
      </div>
    </Dialog>
  );
}

function TermAndConditionModal(props) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const termConditionData = useSelector(
    (state) => state.footers.termConditionData
  );

  useEffect(() => {
    let dateLanguage = CheckLanguage() || "en";
    dispatch(getTermCondition(`terms-cond?locale=${dateLanguage}`));
  }, [dispatch]);

  return (
    <Dialog
      open={props.openModal}
      TransitionComponent={Zoom}
      // onClose={props.closeModal}
    >
      <TopRoot>
        <div className="w-full h-auto flex flex-row justify-between items-start">
          <PrimaryText className={titleTxt}>
            {t("footer.termCondition")}
          </PrimaryText>
          <CloseBtn>
            <IconButton onClick={props.closeModal}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </CloseBtn>
        </div>
        <BottomRoot>
          <Content>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={termConditionData.toString()}
            />
          </Content>
        </BottomRoot>
      </TopRoot>
    </Dialog>
  );
}

function PrivacyPolicyModal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const privacyData = useSelector((state) => state.footers.privacypolicyData);

  useEffect(() => {
    let dateLanguage = CheckLanguage() || "en";
    dispatch(getPrivacyPolicy(`privacy-policy?locale=${dateLanguage}`));
  }, [dispatch]);

  return (
    <Dialog
      open={props.openModal}
      TransitionComponent={Zoom}
      // onClose={props.closeModal}
    >
      <TopRoot>
        <div className="w-full h-auto flex flex-row justify-between items-start">
          <PrimaryText className={titleTxt}>
            {t("footer.PrivacyPolicy")}
          </PrimaryText>
          <CloseBtn>
            <IconButton onClick={props.closeModal}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </CloseBtn>
        </div>
        <BottomRoot>
          <Content>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={privacyData.toString()}
            />
          </Content>
        </BottomRoot>
      </TopRoot>
    </Dialog>
  );
}

function SuccessModal(props) {
  const { t } = useTranslation("common");

  const Content = () => {
    return (
      <div className="text-center">
        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
          {t("successModal.bookingConfirm")}
        </h3>
        <p className="text-gray-600 my-2 px-6">
          {t("successModal.paymentSuccess")}
        </p>
        <p className="text-gray-600 mb-2 px-6">
          {t("successModal.confirmEmailSent")}
        </p>
        <div className="py-4 text-center">
          <button
            onClick={props.closeModal}
            className="px-6 bg-primary hover:bg-primary2 text-white font-semibold py-2 rounded-md"
          >
            {t("successModal.goBack")}
          </button>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={props.openModal}
      // onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      <div className="bg-gray-100 h-auto">
        <div className="bg-white py-2  md:mx-auto">
          <svg
            viewBox="0 0 24 24"
            className="text-green-400 w-16 h-16 mx-auto my-4"
          >
            <path
              fill="currentColor"
              d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
            ></path>
          </svg>
          <Content />
        </div>
      </div>
    </Dialog>
  );
}

function BookingAlertMOdal(props) {
  return (
    <Dialog
      fullScreen
      open={props.openModal}
      onClose={props.closeModal}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <div className="bg-[#464646ba] py-6 pt-40 px-4 w-full h-full d-flex justify-center items-center gap-2">
        <div className="w-full h-[80px] flex justify-center items-center">
          <svg className="w-16 h-16 bg-transparent border-4 animate-spin rounded-full border-t-[white] border-[#9C8053]" />
        </div>
        <div className="text-center">
          <PrimaryText className="text-white font-[Cambay] text-[19px]">
            {
              "Please wait for while, don't refresh we are booking your tee time"
            }
          </PrimaryText>
        </div>
      </div>
    </Dialog>
  );
}

function EmptyCartModal(props) {
  const { t } = useTranslation("common");
  return (
    <Dialog
      open={props.openModal}
      // onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      <div className="bg-white py-8 px-6 w-full h-full flex justify-center items-center gap-4 flex-col text-center">
        <div className="text-center">
          <PrimaryText className="text-black font-[Cambay] text-[21px]">
            {props.errorMessage}
          </PrimaryText>
        </div>
        <button
          onClick={props.closeModal}
          className="px-2 bg-primary hover:bg-primary2 text-white font-semibold py-2 rounded-md"
        >
          {t("successModal.goBack")}
        </button>
      </div>
    </Dialog>
  );
}

const TopRoot = tw.div`
w-full 
h-auto
md:pl-8 sm:pl-2 pl-[10px]
md:pr-8 sm:pr-2 pr-[6px]
sm:py-2  py-[5px] 
overflow-x-hidden
custom_scroll_2
`;
const BottomRoot = tw.div`
w-full
h-[70vh]
whitespace-pre-line 
flex 
flex-col
custom-scroll 
overflow-y-auto
overflow-x-hidden
`;
const titleTxt = `
md:text-3xl sm:text-2xl sm2:text-xl text-lg 
text-textColor2
font-bold 
font-[Cambay]
 mb-8 
 underline 
 underline-offset-8
`;
const CloseBtn = tw.div`
w-[50px] 
h-[50px] 
md:mr-[-38px] mr-0 
mt-[-7px] 
flex 
items-start 
justify-end
`;
const Content = tw.div`
break-words 
hyphens-auto
`;

const BtnView = tw.div`
w-full 
h-auto 
flex flex-row 
items-center justify-start 
gap-4 
pl-4
`;

export {
  CustomModal,
  TimeOutModal,
  TermAndConditionModal,
  PrivacyPolicyModal,
  SuccessModal,
  BookingAlertMOdal,
  EmptyCartModal,
};
