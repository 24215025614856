import React, { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Zoom } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { HeaderMenu, PrimaryText } from "@components";
import { isModal, userLogOut } from "@store";
import LoginModal from "./widgets/loginModal";
import AccountMenu from "./widgets/accountMenu";
import SuccessModal from "./widgets/successModal";
import RegisterModal from "./widgets/registerModal";
import "../containerStyle.css";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { competitionId } = useParams();

  const { i18n } = useTranslation("common");
  const data = useSelector((state) => state.header.headerData);
  const headerMenu = useSelector((state) => state.menu.menus);
  const headerData = data?.logo;
  const loginData = useSelector((state) => state.users.login);
  const registerData = useSelector((state) => state.users.register);
  const userStatus = useSelector((state) => state.users.status);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      activeUrl: window.location.pathname,
      menuData: [],
      menuModal: null,
      loginModal: false,
      registerModal: false,
      successModal: false,
      userData: null,
    }
  );

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#ffffff",
      },
    },
  });

  let isTeesBookingMenu = state.menuData?.find(
    (e) => e?.title === "TEE TIME BOOKING"
  );

  const pathMap = {
    "/check-out": "/",
    "/profile": "/",
    "/cookie": "/",
    "/setting": "/",
    "/setting/changeLanguage": "/",
    "/setting/change-password": "/",
    "/forgot-password": "/",
    "/reset-password": "/",
    "/cancel-booking": "/",
    "/tournament/checkout": "/tournament",
  };

  const active_URL =
    pathMap[window.location.pathname] ??
    (competitionId !== undefined ? "/tournament" : window.location.pathname);

  useEffect(() => {
    const users = localStorage.getItem("UserData");
    if (users !== null) {
      setState({ userData: JSON.parse(users) });
    } else {
      setState({ userData: null });
    }
  }, [userStatus, loginData, registerData]);

  useEffect(() => {
    if (state.loginModal || state.registerModal) {
      dispatch(isModal(true));
    } else {
      dispatch(isModal(false));
    }
  }, [dispatch, state.loginModal, state.registerModal]);

  useEffect(() => {
    setState({
      activeUrl: active_URL,
    });
  }, [active_URL, location]);

  // useEffect(() => {
  //   headerMenu?.map((item, index) => {
  //     item?.attributes?.slug === "header" &&
  //       setState({ menuData: item?.attributes?.items?.data });
  //   });
  // }, [headerMenu]);
  useEffect(() => {
    headerMenu?.forEach((item) => {
      if (item?.slug === "header") {
        setState({ menuData: item?.items });
      }
    });
  }, [headerMenu]);

  const closeModal = () => {
    setState({
      loginModal: false,
      registerModal: false,
      successModal: false,
      activeUrl: active_URL,
    });
  };
  const closeLoginModal = () => {
    setState({
      loginModal: false,
      registerModal: true,
    });
  };
  const closeRegisterModal = () => {
    setState({
      registerModal: false,
      loginModal: true,
    });
  };
  const openSuccessModal = () => {
    setState({
      loginModal: false,
      registerModal: false,
      successModal: true,
    });
  };
  const closeLoginErrorModal = () => {
    setState({
      loginModal: true,
      registerModal: false,
      successModal: false,
    });
  };
  const closeRegisterErrorModal = () => {
    setState({
      loginModal: false,
      registerModal: true,
      successModal: false,
    });
  };

  const activeNav = (e) => {
    if (e?.url === "/login") {
      setState({
        activeUrl: e?.url,
        menuModal: null,
        loginModal: true,
      });
    } else {
      navigate(`${e?.url}`);
      setState({
        activeUrl: e?.url,
        menuModal: null,
        loginModal: false,
      });
    }
  };

  const menu_Page = (value) => {
    setState({
      menuModal: null,
    });
    navigate(value);
  };

  const logOut = () => {
    dispatch(userLogOut());
    setState({
      menuModal: null,
      userData: null,
    });
    i18n.changeLanguage("en");
    navigate("/");
  };

  const NavButton = (props) => {
    if (props?.title === "LOGIN" && state.userData !== null) {
      return (
        <AccountMenu
          data={state.userData}
          log_OUT={logOut}
          goToPage={menu_Page}
        />
      );
    } else {
      return (
        <HeaderMenu
          selectBtn={state.activeUrl === props.value?.url ? true : false}
          onClick={() => activeNav(props.value)}
        >
          {props.title}
        </HeaderMenu>
      );
    }
  };

  return (
    <>
      {data?.isHeaderTextVisible &&
        window.location.pathname === "/" &&
        isTeesBookingMenu && (
          <div
            class="bg-primary rounded-b text-teal-darkest py-[6px] shadow-xl drop-shadow-xl text-center flex flex-1 w-full px-2 h-auto justify-center items-center flex-row gap-x-2"
            role="alert"
          >
            <InfoOutlinedIcon fontSize="large" sx={{ color: "#ffffff" }} />
            <>
              <PrimaryText className="text-textColor text-[18px] font-black text-center tracking-wider">
                {data?.headerText}
              </PrimaryText>
            </>
          </div>
        )}
      <Container>
        {headerData && (
          <button onClick={() => navigate("/")} className="pl-2">
            <Logo src={`${headerData?.url}`} alt="Logo" />
          </button>
        )}
        <Navbar>
          {state.menuData.map((items, index) => (
            <div
              key={`headers${index}`}
              className={`${
                state.menuData.length - 1 === index ? "" : "mr-10"
              }`}
            >
              <NavButton value={items} title={items?.title} />
            </div>
          ))}
        </Navbar>

        <div className="md3:hidden flex">
          <ThemeProvider theme={theme}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={(e) => setState({ menuModal: e.currentTarget })}
            >
              {Boolean(state.menuModal) ? (
                <CloseIcon fontSize="large" sx={{ color: "#ffffff" }} />
              ) : (
                <MenuIcon fontSize="large" sx={{ color: "#ffffff" }} />
              )}
            </Button>
          </ThemeProvider>
          <Menu
            anchorEl={state.menuModal}
            open={Boolean(state.menuModal)}
            TransitionComponent={Zoom}
            onClose={() => setState({ menuModal: null })}
          >
            {state.menuData?.map((item, index) => {
              if (item?.title === "LOGIN" && state.userData !== null) {
                return (
                  <div key={`profileMenu${index}`}>
                    <MenuItem
                      sx={{
                        color: "rgb(24,23,23)",
                        p: "10px 30px",
                        cursor: "pointer",
                      }}
                      divider={true}
                      onClick={() => menu_Page("/profile")}
                    >
                      <span className="font-[Cambay]"> {"PROFILE"}</span>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color:
                          state.activeUrl === item?.url
                            ? "rgb(0,152,54)"
                            : "rgb(24,23,23)",
                        p: "10px 30px",
                        cursor: "pointer",
                      }}
                      divider={true}
                      onClick={() => menu_Page("/setting")}
                    >
                      <span className="font-[Cambay]"> {"SETTING"}</span>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "rgb(24,23,23)",
                        p: "10px 30px",
                        cursor: "pointer",
                      }}
                      divider={true}
                      onClick={logOut}
                    >
                      <span className="font-[Cambay]"> {"LOGOUT"}</span>
                    </MenuItem>
                  </div>
                );
              } else {
                return (
                  <MenuItem
                    key={`menu${index}`}
                    sx={{
                      color:
                        state.activeUrl === item?.url
                          ? "#9B7C52"
                          : "rgb(24,23,23)",
                      p: "10px 30px",
                      cursor:
                        state.activeUrl === item?.url ? "default" : "pointer",
                    }}
                    divider={state.menuData.length - 1 === index ? false : true}
                    onClick={() => activeNav(item)}
                  >
                    <span className="font-[Cambay]"> {item?.title}</span>
                  </MenuItem>
                );
              }
            })}
          </Menu>
        </div>
      </Container>
      <LoginModal
        openModal={state.loginModal}
        closeModal={closeModal}
        closeLoginModal={closeLoginModal}
        openSuccessModal={openSuccessModal}
      />
      <RegisterModal
        openModal={state.registerModal}
        closeModal={closeModal}
        closeRegisterModal={closeRegisterModal}
        openSuccessModal={openSuccessModal}
      />
      <SuccessModal
        openModal={state.successModal}
        closeModal={closeModal}
        closeLoginErrorModal={closeLoginErrorModal}
        closeRegisterErrorModal={closeRegisterErrorModal}
      />
    </>
  );
}

const Container = tw.div`
w-full
max-w-[1500px]
h-[140px]
flex
flex-row
justify-between
items-center
pr-5
pl-4
z-10
m-auto	
`;
const Logo = tw.img`
w-[100px]
h-[83px]
`;
const Navbar = tw.div`
w-auto
h-full
md3:flex
flex-row
justify-end
items-center
md2:hidden
hidden
`;
export default Header;
